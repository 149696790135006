let sallad = [
  {
    id: "caesarsallad",
    typ: "Sallad",
    namn: "CAESARSALLAD",
    takeaway: "140",
    pris: "150",
    text:
      "Romansallad, riven parmesan, caesarsås och kyckling. Toppas med krutonger och strimlad bacon.",
  },
  {
    id: "chevresallad",
    namn: "CHÈVRESALLAD",
    takeaway: "140",
    pris: "150",
    text:
      "Mixsallad, chèvre, lök, valnötter, äppelvinäger och honung (serveras med bröd).",
  },
  {
    id: "parmasallad",
    typ: "Sallad",
    namn: "PARMASALLAD",
    takeaway: "150",
    pris: "160",
    text: "Mixsallad, tomat, oliver, parmaskinka och soltorkade tomater",
  },
  {
    id: "raksallad",
    namn: "RÄKSALLAD",
    takeaway: "147",
    pris: "157",
    text:
      "Mixsallad, räkor, ägg, citron, körsbärstomater, dill och dressing gjord av citron, majonnäs, svartpeppar och salt.",
  },
];

export default sallad;
