import pizza from "./pizza";
import pasta from "./pasta";

let lunch = {
  1: [
    pasta[0],
    pasta[5],
    pizza[1],
  ],
  2: [
    pasta[1],
    pasta[2],
    pizza[11],
  ],
  3: [
    pasta[3],
    pasta[8],
    pizza[4],
  ],
  4: [
    pasta[9],
    {
      typ: "Pasta",
      namn: "Salsiccia",
      text: "Tomatsås, korv, chili. Toppas med Grana Padano",
    },
    pizza[10],
  ],
  5: [
    pasta[4],
    pasta[6],
    pizza[6],
  ],
};

export default lunch;
