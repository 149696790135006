let dryck = {
  "Cocktails & Drinkar": [
    {
      namn: "ESPRESSO MARTINI",
      text: "Kaffe, vodka, kaffelikör",
      pris: "119",
    },
    {
      namn: "IRISH COFFEE",
      text: "Kaffe, whiskey, vispad grädde, farinsocker",
      pris: "119",
    },
    {
      namn: "STRAWBERRY DAIQUIRI",
      text: "Ljus rom, jordgubbar, limejuice, florsocker",
      pris: "115",
    },
    {
      namn: "APEROL SPRITZ",
      text: "Mousserande vin, Aperol, sodavatten, apelsin",
      pris: "105",
    },
    {
      namn: "BELLINI",
      text: "Persikojuice, Prosecco",
      pris: "115",
    },
    {
      namn: "MARGARITA",
      text: "Lime, ljus tequila, cointreau, krossad is",
      pris: "115",
    },
    {
      namn: "APPLETINI",
      text: "Sourz Apple, vodka, pressad citron",
      pris: "115",
    },
    {
      namn: "SCREWDRIVER",
      text: "Vodka och juice",
      pris: "115",
    },
    {
      namn: "REDBULL VODKA 118:-",
      text: "",
      pris: "118",
    },
  ],
  "4 CL": [
    {
      namn: "GIN OCH TONIC",
      pris: "99",
    },
    {
      namn: "LIMONCELLO TONIC",
      pris: "99",
    },
    {
      namn: "CAMPARI ORANGE ETC.",
      pris: "99",
    },
  ],
  "Kalla drycker": [
    {
      namn: "EKO CALVANINA",
      text: "Lemonad. Mandarin.",
      pris: "44",
    },
    {
      namn: "APELSINJUICE",
      pris: "28",
    },
    {
      namn: "ISTE PERSIKA",
      text: "",
      pris: "44",
    },
    {
      namn: "ISLATTE NATURELL",
      text: "Caramell. Vanilj. Hasselnöt.",
      pris: "48",
    },
    {
      namn: "SAN PELLEGRINO",
      text: "Sodavatten. Blodapelsin. Apelsin. Citron.",
      pris: "34",
    },
    {
      namn: "RAMLÖSA",
      text: "Original, Citron",
      pris: "32",
    },
    {
      namn: "Läsk",
      text: "Coca Cola, Fanta, Cola Zero, Sprite",
      pris: "34",
    },
  ],
  "Varma drycker": [
    {
      namn: "CAPPUCCINO",
      text: "",
      pris: "39 / 44",
    },
    {
      namn: "KAFFE LATTE",
      text: "",
      pris: "39 / 44",
    },
    {
      namn: "MEZZO/CORTADO",
      text: "",
      pris: "34",
    },
    {
      namn: "MACCHIATTO",
      text: "",
      pris: "32 / 39",
    },
    {
      namn: "AMERICANO",
      text: "",
      pris: "34",
    },
    {
      namn: "LATTE MACCHIATTO",
      text: "",
      pris: "39",
    },
    {
      namn: "ESPRESSO",
      text: "",
      pris: "29 / 39",
    },
    {
      namn: "CHAI LATTE",
      text: "",
      pris: "39",
    },
    {
      namn: "BRYGGKAFFE",
      text: "(Påtår ingår)",
      pris: "34",
    },
    {
      namn: "TE",
      text: "Earl Grey, Grönt, Sörgårdsté",
      pris: "29",
    },
  ],
  Rött: [
    {
      namn: "HUSETS",
      text: "",
      pris: "74",
    },
    {
      namn: "MONTEPULCIANO D´ABRUZZO",
      text: "",
      pris: "96 / 366",
    },
    {
      namn: "AMORE ROSSO TOSCANA",
      text: "",
      pris: "98 / 378",
    },
    {
      namn: "MONTECCHIO",
      text: "",
      pris: "399",
    },
  ],
  Vitt: [
    {
      namn: "HUSETS",
      text: "",
      pris: "74",
    },
    {
      namn: "TREBBIANO D´ABRUZZO",
      text: "",
      pris: "96 / 366",
    },
    {
      namn: "PINOT GRIGIO LA BOATINA",
      text: "",
      pris: "99 / 399",
    },
    {
      namn: "HUSETS ROSÉ",
      text: "",
      pris: "79",
    },
  ],
  Bubbel: [
    {
      namn: "HUSETS PROSECCO",
      text: "",
      pris: "75",
    },
    {
      namn: "ÄPPELCIDER TORR",
      text: "",
      pris: "69",
    },
    {
      namn: "PÄRONCIDER",
      text: "",
      pris: "69",
    },
  ],
  Öl: [
    {
      namn: "BRYGGMÄSTAREN, FAT",
      text: "40cl",
      pris: "65",
    },
    {
      namn: "PERONI, FAT",
      text: "33cl/40cl",
      pris: "62 / 74",
    },
    {
      namn: "WARSTEINER",
      text: "33cl",
      pris: "62",
    },
    {
      namn: "PUNK IPA",
      text: "33cl",
      pris: "62",
    },
    {
      namn: "STELLA",
      text: "33cl",
      pris: "62",
    },
    {
      namn: "PERONI ALKOHOLFRI",
      text: "33cl",
      pris: "49",
    },
    {
      namn: "PERONI NASTRO AZZURO",
      text: "33cl",
      pris: "62",
    },
    {
      namn: "MORETTI",
      text: "33cl",
      pris: "69",
    },
  ],
};

export default dryck;
