let forratter = [
  {
    id: "caprese",
    namn: "CAPRESE",
    pris: "120",
    text: "Buffalo mozzarella, balsamika, basilika och körsbärstomater.",
  },
  {
    id: "carpaccio",
    namn: "CARPACCIO",
    pris: "134",
    text: "Skivad oxfilé, parmesan, capris, schalottenlök och tryffelmajonnäs.",
  },
  {
    id: "marinerade-oliver",
    namn: "MARINERADE OLIVER",
    pris: "64",
    text: "",
  },
  {
    id: "ostbricka",
    namn: "OSTBRICKA",
    pris: "150",
    text: "Kombinerat med olika ostar.",
  },
  {
    id: "vitloksbrod",
    namn: "VITLÖKSBRÖD",
    pris: "69",
    text: "Smör, vitlök och färsk basilika.",
  },
  {
    id: "bruschetta",
    namn: "BRUSCHETTA",
    pris: "74",
    text:
      "Tomat, rödlök, basilica, vitlök, olivolja, balsamico och chilisås. Mediumstark.",
  },
  {
    id: "antipasti",
    namn: "ANTIPASTI",
    pris: "239/404",
    text: "Vi har kombinerat olika ostar, charkuterier och annat gott.",
  },
];

export default forratter;
