let pizza = [
  {
    id: "margherita",
    typ: "Pizza",
    namn: "MARGHERITA",
    takeaway: "147",
    pris: "157",
    text: "Fior Di Latte, tomatsås, toppas med färsk basilika och oregano.",
  },
  {
    id: "capra",
    typ: "Pizza",
    namn: "CAPRA",
    takeaway: "154",
    pris: "164",
    text:
      "Fior Di Latte, tomatsås, krämig getost, honung och valnötter. Toppas med oregano.",
  },
  {
    id: "capriciossa",
    typ: "Pizza",
    namn: "CAPRICIOSSA",
    takeaway: "154",
    pris: "164",
    text:
      "Fior Di Latte, tomatsås, prosciutto cotto (kokt skinka) och champinjoner. Toppas med oregano.",
  },
  {
    id: "pomodoro",
    typ: "Pizza",
    namn: "POMODORO",
    takeaway: "151",
    pris: "161",
    text:
      "Fior Di Latte, tomatsås, soltorkade tomater, champinjoner och basilika. Toppas med oregano.",
  },
  {
    id: "veggo",
    typ: "Pizza",
    namn: "VEGGO",
    takeaway: "154",
    pris: "164",
    text:
      "Fior Di Latte, tomatsås, oliver, tomater, champinjoner och rödlök. Toppas med pesto och oregano.",
  },
  {
    id: "pollo",
    typ: "Pizza",
    namn: "POLLO",
    takeaway: "154",
    pris: "164",
    text:
      "Fior Di Latte, tomatsås, kyckling, vitlök och pesto. Toppas med oregano.",
  },
  {
    id: "parma",
    typ: "Pizza",
    namn: "PARMA",
    takeaway: "157",
    pris: "167",
    text:
      "Fior Di Latte, tomatsås, lufttorkad skinka, rucola. körsbärstomater och crema di balsamico. Toppas med oregano.",
  },
  {
    id: "milano",
    typ: "Pizza",
    namn: "MILANO",
    takeaway: "154",
    pris: "164",
    text:
      "Fior Di Latte, tomatsås, Salame Milano och rucola. Toppas med oregano.",
  },
  {
    id: "quattro-formaggio",
    typ: "Pizza",
    namn: "QUATTRO FORMAGGIO",
    takeaway: "157",
    pris: "167",
    text:
      "Fior Di Latte, tomatsås, gorgonzola och grana padano, samt pecorino. Toppas med oregano.",
  },
  {
    id: "pizza-il-cortile",
    typ: "Pizza",
    namn: "PIZZA IL CORTILE",
    takeaway: "161",
    pris: "171",
    text:
      "Fior Di Latte, tomatsås, tomater, paprika, oxfilé, peperoni, oliver och bearnaisesås. Toppas med oregano.",
  },
  {
    id: "pizza-funghi",
    typ: "Pizza",
    namn: "FUNGHI",
    takeaway: "154",
    pris: "164",
    text: "Fior Di latte, tomatsås och champinjoner. Toppas med oregano.",
  },
  {
    id: "pizza-tuna",
    typ: "Pizza",
    namn: "TUNA",
    takeaway: "157",
    pris: "167",
    text: "Fior Di latte, tomatsås, rödlök och tonfisk. Toppas med oregano.",
  },
];

export default pizza;
