let efterratt = [
  {
    id: "cremebrulee",
    namn: "CRÈME BRÛLÉE",
    pris: "79",
    text: "Veckans smak (fråga personalen).",
  },
  {
    id: "tiramisu",
    namn: "TIRAMISU",
    pris: "54",
    text: "",
  },
  {
    id: "chokladfondant",
    namn: "CHOKLADFONDANT",
    pris: "95",
    text: "Serveras med glass och blåbär.",
  },
  {
    id: "pannacotta",
    namn: "PANNACOTTA",
    pris: "49",
    text: "Serveras med bärsås.",
  },
  {
    id: "glass",
    namn: "GLASS",
    pris: "1 KULA 34",
    pris2: "2 KULOR 49",
    text: "Vanlij/choklad.",
  },
];

export default efterratt;
