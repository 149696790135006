<template>
  <div>
    <section
      class="uk-height-large uk-background-blend-overlay uk-background-secondary uk-background-cover uk-light uk-flex toscana"
      uk-height-viewport
      uk-parallax="bgy: -100"
    >
      <div
        class="uk-container-small uk-text-center uk-margin-auto uk-margin-auto-vertical"
      >
        <img
          class="uk-width-1-1 uk-padding-large uk-padding-remove-top"
          src="../assets/ilcortile.svg"
        />
        <!-- <h3>
          Nya ägare, samma trevliga personal och samma goda meny.
        </h3> -->
        <p
          class="uk-padding-large uk-padding-remove-top uk-padding-remove-bottom"
        >
          I hjärtat av Mölndal utanför gallerian hittar ni Bistro &amp; Café il
          Cortile. Hos oss avnjuter du pizza som är bakad med kärlek på
          Napolitanskt tipo ”00”- mjöl, pastarätter på färsk pasta, fräscha
          sallader och annat gott. Vi erbjuder såklart italienska bakelser och
          desserter
        </p>
        <p class="uk-h4 uk-margin-remove-top">Benvenuto!</p>
        <!-- <a
          href="#lunch"
          class="uk-button uk-button-primary uk-border-pill uk-margin-right"
          uk-scroll
          >Lunchmenyn</a
        > -->
        <a
          href="#meny"
          class="uk-button uk-button-primary uk-border-pill"
          uk-scroll
          >A la Carte</a
        >
      </div>
    </section>

    <!-- <section id="lunch" class="uk-section uk-section-default">
      <div class="uk-container">
        <h2 class="uk-h3 uk-text-center">Lunch</h2>
        <div class="uk-child-width-1-2@m" uk-grid>
          <div class="uk-margin-auto uk-text-center">
            <h2>Dagens lunch, 109kr</h2>
            <p>Kl. 11-14, fika och kaffe ingår</p>
            <div v-for="(v, index) in lunch" :key="index">
              <div
                class="meny small uk-margin-top uk-margin-small-bottom"
                uk-grid
              >
                <div class="uk-width-expand">
                  {{ veckodagar[index - 1] }}
                </div>
              </div>
              <div v-for="(r, i) in v" :key="i">
                <div class="uk-text-bold uk-text-uppercase">
                  <span v-if="r.typ">{{ r.typ }}</span> {{ r.namn }}
                </div>
                <p class="uk-margin-remove-top">
                  {{ r.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section id="meny" class="uk-section uk-section-muted">
      <div class="uk-container">
        <h2 class="uk-h3 uk-text-center">A la Carte</h2>
        <div class="uk-child-width-1-2@m" uk-grid>
          <div>
            <!-- FÖRRÄTTER -->
            <div class="uk-margin">
              <h2 class="uk-text-center">Förrätter</h2>
              <div class="uk-margin-bottom uk-flex-bottom" uk-grid>
                <div class="uk-width-expand" />
              </div>
              <div v-for="(f, index) in forratter" :key="index">
                <div class="meny" uk-grid>
                  <div class="uk-width-expand">{{ f.namn }}</div>
                  <div class="uk-flex-right">
                    <span v-if="f.takeaway">{{ f.takeaway }} / </span
                    >{{ f.pris }}
                  </div>
                </div>
                <hr />
                <p class="uk-margin-medium-bottom">{{ f.text }}</p>
              </div>
            </div>
            <!-- END FÖRRÄTTER -->
            <!-- PASTA -->
            <div class="uk-margin">
              <h2 class="uk-text-center">Pasta</h2>
              <div class="uk-margin-medium-bottom uk-text-center uk-text-small">
                Alla rätter görs på färsk pasta
              </div>
              <div class="uk-margin-bottom uk-flex-bottom" uk-grid>
                <div class="uk-width-expand">Barn portion 89kr</div>
                <div class="uk-flex-right uk-text-small">
                  take away / äta här
                </div>
              </div>
              <div v-for="(p, index) in pasta" :key="index">
                <div class="meny" uk-grid>
                  <div :class="{ veggo: p.veggo }" class="uk-width-expand">
                    {{ p.namn }} <span v-if="p.veggo">V</span>
                  </div>
                  <div class="uk-flex-right">
                    <span v-if="p.takeaway">{{ p.takeaway }} / </span
                    >{{ p.pris }}
                  </div>
                </div>
                <hr />
                <p class="uk-margin-medium-bottom">{{ p.text }}</p>
              </div>
            </div>
            <!-- END PASTA -->
            <!-- EFTERRÄTT -->
            <div class="uk-margin">
              <h2 class="uk-text-center">Efterrätt</h2>
              <div class="uk-margin-bottom uk-flex-bottom" uk-grid>
                <div class="uk-width-expand" />
              </div>
              <div v-for="(eft, index) in efterratt" :key="index">
                <div class="meny" uk-grid>
                  <div class="uk-width-expand">{{ eft.namn }}</div>
                  <div v-if="eft.id !== 'glass'" class="uk-flex-right">
                    <span v-if="eft.takeaway">{{ eft.takeaway }} / </span
                    >{{ eft.pris }}
                  </div>
                  <div v-if="eft.id == 'glass'" class="uk-flex-right">
                    <p class="uk-margin-remove">{{ eft.pris }}</p>
                    <p class="uk-margin-remove">{{ eft.pris2 }}</p>
                  </div>
                </div>
                <hr />
                <p class="uk-margin-medium-bottom">{{ eft.text }}</p>
              </div>
            </div>
            <!-- END EFTERRÄTT -->
          </div>
          <div>
            <!-- SALLAD -->
            <div class="uk-margin">
              <h2 class="uk-text-center">Sallad</h2>
              <div v-for="(s, index) in sallad" :key="index">
                <div class="meny" uk-grid>
                  <div class="uk-width-expand">{{ s.namn }}</div>
                  <div class="uk-flex-right">
                    <span v-if="s.takeaway">{{ s.takeaway }} / </span
                    >{{ s.pris }}
                  </div>
                </div>
                <hr />
                <p class="uk-margin-medium-bottom">{{ s.text }}</p>
              </div>
            </div>
            <!-- END SALLAD -->
            <!-- PIZZA -->
            <div class="uk-margin">
              <h2 class="uk-text-center">Pizza</h2>
              <div class="uk-margin-small-bottom uk-text-center uk-text-small">
                Våra pizzor görs på napolitanskt tipo `00`mjöl
              </div>
              <div class="uk-margin-bottom">
                <strong>FIOR DI LATTE</strong> Mozzarella gjord på komjölk. Mild
                smak och jämn konsistens.
              </div>
              <div class="uk-margin-bottom uk-flex-bottom" uk-grid>
                <div class="uk-width-expand">
                  Barn portion 89kr<br />
                  Glutenfritt +25kr
                </div>
                <div class="uk-flex-right uk-text-small">
                  take away / äta här
                </div>
              </div>
              <div v-for="(p, index) in pizza" :key="index">
                <div class="meny" uk-grid>
                  <div class="uk-width-expand">{{ p.namn }}</div>
                  <div class="uk-flex-right">
                    <span v-if="p.takeaway">{{ p.takeaway }} / </span
                    >{{ p.pris }}
                  </div>
                </div>
                <hr />
                <p class="uk-margin-medium-bottom">{{ p.text }}</p>
              </div>
            </div>
            <!-- END PIZZA -->
          </div>
        </div>
      </div>
    </section>

    <section id="dryck" class="uk-section uk-section-default">
      <div class="uk-container">
        <h2 class="uk-h3 uk-text-center">Dryckesmeny</h2>
        <div class="uk-child-width-1-2@m" uk-grid>
          <div v-for="(d, namn, index) in dryck" :key="index">
            <h2 class="uk-text-center">{{ namn }}</h2>
            <div class="uk-margin" v-for="(t, index) in d" :key="index">
              <div class="meny" uk-grid>
                <div class="uk-width-expand uk-text-uppercase">
                  {{ t.namn }}
                </div>
                <div class="uk-flex-right">
                  {{ t.pris }}
                </div>
              </div>
              <div v-if="t.text">{{ t.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="uk-section uk-section-default">
      <div class="uk-container">
        <div class="uk-child-width-expand@s" uk-grid>
          <div class="uk-grid-item-match">
            <div
              class="uk-card uk-card-secondary uk-card-body uk-text-center uk-padding-large"
            >
              <img
                class="uk-width-1-1 uk-padding"
                src="../assets/ilcortile.svg"
              />
              <h2>Öppettider</h2>
              <p class="uk-margin-remove-bottom">Måndag, Stängt</p>
              <p class="uk-margin-remove-bottom uk-margin-remove-top">
                Tisdag, 16:00 - 21:30
              </p>
              <p class="uk-margin-remove-bottom uk-margin-remove-top">
                Onsdag, 16:00 - 21:30
              </p>
              <p class="uk-margin-remove-bottom uk-margin-remove-top">
                Torsdag, 16:00 - 21:30
              </p>
              <p class="uk-margin-remove-bottom uk-margin-remove-top">
                Fredag, 14:00 - 22:00
              </p>
              <p class="uk-margin-remove-bottom uk-margin-remove-top">
                Lördag, 11:00 - 22:00
              </p>
              <p class="uk-margin-remove-top">Söndag, 11:00 - 20:00</p>
              <hr class="color" />
              <p class="uk-margin-remove-bottom">KONTAKT / BOKA / TAKEAWAY</p>
              <a href="tel:+46760072541" class="uk-margin-remove-top">
                Tel: +46 76-007 25 41
              </a>
              <div class="uk-flex-center uk-margin-top uk-grid-small" uk-grid>
                <div>
                  <a href="https://www.facebook.com/cafeilcortile/">
                    <i class="fab fa-facebook fa-2x"></i>
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/cafeilcortile/">
                    <i class="fab fa-instagram fa-2x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="uk-background-cover uk-width-1-1 uk-height-1-1"
              :style="`background-image: url(${italy})`"
            ></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import pizza from "../shared/pizza";
import pasta from "../shared/pasta";
import sallad from "../shared/sallad";
// import antipasti from "../shared/antipasti";
import lunch from "../shared/lunch";
import dryck from "../shared/dryck";
import italyImg from "../assets/italy.jpg";
import forratter from "../shared/forratter";
// import alacarte from "../shared/alacarte";
import efterratt from "../shared/efterratt";

export default {
  name: "Home",
  components: {},
  computed: {},
  data: () => ({
    code: "XXX",
    nameMsg: "",
    emailMsg: "",
    phoneMsg: "",
    companyMsg: "",
    messageMsg: "",
    loadingTxt: false,
    errorTxt: "",
    italy: italyImg,
    pizza: pizza,
    pasta: pasta,
    sallad: sallad,
    // antipasti: antipasti,
    lunch: lunch,
    dryck: dryck,
    forratter: forratter,
    // alacarte: alacarte,
    efterratt: efterratt,
    veckans: [],
    kommandeVecka: [],
    dagens: [],
    week: 1,
    today: 0,
    veckodagar: ["Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag"],
    dag: "Måndag",
    veckonr: 1,
  }),
  mounted() {
    this.saveDates();
  },
  methods: {
    sendEmail() {
      if (!this.nameMsg) {
        return (this.errorTxt = "Vänligen skriv in ditt namn");
      }
      if (!this.emailMsg) {
        return (this.errorTxt = "Vänligen skriv in din mailadress");
      }
      // if (!this.getVerification) {
      //   return (this.errorTxt = "reCAPTCHA not done!");
      // }
      if (this.nameMsg && this.emailMsg) {
        this.loadingTxt = true;
      }
      axios
        .post(`https://formspree.io/f/${this.code}`, {
          name: this.nameMsg,
          from: this.emailMsg,
          _subject: `${this.nameMsg} | Meddelande från ilCortile.se`,
          message: this.messageMsg,
          phone: this.phoneMsg,
          company: this.companyMsg,
        })
        .then(() => {
          this.nameMsg = "";
          this.emailMsg = "";
          this.messageMsg = "";
          this.phoneMsg = "";
          this.companyMsg = "";
        })
        .catch((error) => {
          if (error.response) {
            this.errorText = error.response;
          }
        });
    },
    getWeek(dt) {
      var tdt = new Date(dt.valueOf());
      var dayn = (dt.getDay() + 6) % 7;
      tdt.setDate(tdt.getDate() - dayn + 3);
      var firstThursday = tdt.valueOf();
      tdt.setMonth(0, 1);
      if (tdt.getDay() !== 4) {
        tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
      }
      return 1 + Math.ceil((firstThursday - tdt) / 604800000);
    },
    saveDates() {
      let dag;
      let week;
      let date = new Date();
      let today = date.getDay();
      let firstWeekday =
        new Date(date.getFullYear(), date.getMonth(), 1).getDay() - 1;
      if (firstWeekday < 0) firstWeekday = 6;
      let offsetDate = date.getDate() + firstWeekday - 1;
      week = Math.floor(offsetDate / 7);

      let daysArr = this.veckodagar;
      dag = daysArr[today];

      // Store
      this.veckonr = this.getWeek(date);
      this.week = week;
      this.today = today;
      this.dag = dag;
      this.veckans = this.lunch[this.week];

      try {
        this.dagens = this.lunch[this.week][this.today];
      } catch (e) {
        this.dagens = this.lunch[1][this.today];
        console.log(e);
      }

      if (this.today == 0 || this.today == 6) {
        this.dagens = this.lunch[1][5];
        this.kommandeVecka = this.lunch[this.week + 1];
        this.dag = "Helg";
      }
      if ((this.today == 0 || this.today == 6) && this.week == 4) {
        this.kommandeVecka = this.lunch[1];
        this.dag = "Helg";
      }
    },
  },
};
</script>
