let pasta = [
  {
    id: "pesto",
    typ: "Pasta",
    namn: "PESTO",
    takeaway: "157",
    pris: "167",
    text: "Pesto och kyckling, toppas med körsbärstomater och grana padano.",
  },
  {
    id: "gorgonzola",
    typ: "Pasta",
    namn: "GORGONZOLA",
    takeaway: "164",
    pris: "174",
    text:
      "Kyckling i gorgonzolasås och grädde. Toppas med paprika, champinjoner och grana padano.",
  },
  {
    id: "tomato",
    typ: "Pasta",
    namn: "TOMATO",
    takeaway: "154",
    pris: "164",
    text:
      "Mustig tomatsås toppas med buffelmozzarella, grana padano och basilika.",
  },
  {
    id: "bolonese",
    typ: "Pasta",
    namn: "BOLOGNESE",
    takeaway: "154",
    pris: "164",
    text:
      "Tomatsås, nötfärs, lök, vitlök och basilika. Toppas med grana padano.",
  },
  {
    id: "carbonara",
    typ: "Pasta",
    namn: "CARBONARA",
    takeaway: "157",
    pris: "167",
    text:
      "Bacon, ägg, svartpeppar, parmesan parmigiano reggiano och grädde. Toppas med grana padano.",
  },
  {
    id: "tartufo",
    typ: "Pasta",
    namn: "TARTUFO",
    takeaway: "164",
    pris: "174",
    text:
      "Färsk pasta, champinjoner i gräddig tryffelsås. Toppas med salami milano och grana padano.",
  },
  {
    id: "marinara",
    typ: "Pasta",
    namn: "MARINARA",
    takeaway: "164",
    pris: "174",
    text: "Tomatsås, räkor, vitlök, toppas med grana padano.",
  },
  {
    id: "pasta-il-cortile",
    typ: "Pasta",
    namn: "PASTA IL CORTILE",
    takeaway: "177",
    pris: "187",
    text:
      "Grädde, oxfile, paprika, rödlök, champinjoner, vitlök och senap. Demi Glace fond. Toppas med grana padano.",
  },
  {
    id: "gnocchi",
    typ: "Pasta",
    namn: "GNOCCHI",
    takeaway: "164",
    pris: "174",
    text:
      "Grädde, gorgonzola, kyckling och körsbärstomater. Toppas med grana padano.",
  },
  {
    id: "alfredo",
    typ: "Pasta",
    namn: "ALFREDO",
    takeaway: "154",
    pris: "164",
    text: "Grädde, kyckling och vitlök. Toppas med grana padano.",
  },
];

export default pasta;
